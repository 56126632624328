import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import FeedIcon from '@mui/icons-material/Feed'; // 계약 정보
import WatchLaterIcon from '@mui/icons-material/WatchLater'; // 근무
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'; // 교육
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'; // 교육
import SchoolIcon from '@mui/icons-material/School'; // 교육
import ModeEditIcon from '@mui/icons-material/ModeEdit'; // 교육
import TextsmsIcon from '@mui/icons-material/Textsms'; // 민원
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'; // 민원
import ForumIcon from '@mui/icons-material/Forum'; // 민원
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'; // 비용 청구
import PaymentIcon from '@mui/icons-material/Payment'; // 급여/근로
import LeaderboardIcon from '@mui/icons-material/Leaderboard'; // 자산
import SettingsIcon from '@mui/icons-material/Settings'; // 설정

export interface SidebarProps {
  isSidebarOpen: boolean;
  closeSidebar: () => void;
}

export const Sidebar = ({ isSidebarOpen, closeSidebar }: SidebarProps) => {
  /**
   * AA000 = 본사 관리자 코드 / process.env.HEAD_OFFICE_MANAGER_CODE
   * HM000 = 관리 소장 코드 / process.env.APARTMENT_MANAGER_CODE
   * UU000 = 일반 직원 코드 (미화, 경비, 반장) / process.env.NORMAL_EMPLOYEE_CODE
   */
  const verifiedPermitCode = sessionStorage.getItem('permitCode');

  const navigate = useNavigate();

  const menuData: {
    ActionCode?: string;
    ActionOffSet?: string;
    ActionType?: string;
    LinkADDR?: string;
    MenuCode?: string;
    NameCHI?: string;
    NameENG?: string;
    NameKOR?: string;
    ParentsCode?: string;
    SubMenu: {
      ActionCod?: string;
      ActionOffSet?: string;
      ActionType?: string;
      LinkADDR?: string;
      MenuCode?: string;
      NameCHI?: string;
      NameENG?: string;
      NameKOR?: string;
      OrderIdx?: number;
      length: number;
    };
  }[] = useSelector((state: RootState) => state.loggedInMenuData.menuDataValue);

  const loadMenu: {
    ActionCode?: string;
    ActionOffSet?: string;
    ActionType?: string;
    LinkADDR?: string;
    MenuCode?: string;
    NameCHI?: string;
    NameENG?: string;
    NameKOR?: string;
    OrderIdx?: number;
    ParentsCode?: string;
    SubMenu: {
      [x: string]: any; // map 에러처리 일단 이걸로 막았는데... 어떻게 된 건지 알아볼 것
      ActionCod?: string;
      ActionOffSet?: string;
      ActionType?: string;
      LinkADDR?: string;
      MenuCode?: string;
      NameCHI?: string;
      NameENG?: string;
      NameKOR?: string;
      OrderIdx?: number;
      length: number;
    };
  }[] = [];
  let filteredMenu: any[] = [];

  function processingMenuData() {
    menuData.map(menuList => {
      if (menuList.SubMenu.length > 0) {
        loadMenu.push(menuList);
      }
    });

    filteredMenu = loadMenu.map(majorMenu => {
      if (majorMenu.SubMenu.length > 0) {
        return {
          // [TAG] 1번째 메뉴
          isOpen: false,
          // menuCode: majorMenu.MenuCode, -> [MEMO] 대분류 카테고리에 아이콘 미적용시 해당 코드 활성화
          menuCode: majorMenu.MenuCode,
          nameKR: majorMenu.NameKOR,
          subMenu: majorMenu.SubMenu.map(
            (subItem: {
              MenuCode: string;
              NameKOR: string;
              SubMenu: any[];
            }) => {
              // [TAG] 2번째 메뉴
              if (subItem.MenuCode === 'CM101') {
                // CM101 단지 계약 정보(계약 정보 조회)
                return {
                  menuCode: subItem.MenuCode,
                  nameKR: subItem.NameKOR,
                  subMenu: [],
                  // path: '/apartmentContract',
                  path: '/CM101',
                };
              }
              if (subItem.MenuCode === 'CS201') {
                // CS201 급여 명세서
                return {
                  menuCode: subItem.MenuCode,
                  nameKR: subItem.NameKOR,
                  subMenu: [],
                  path: '/CS201',
                };
              }
              if (subItem.MenuCode === 'CS302') {
                // CS302 급여 대장
                return {
                  menuCode: subItem.MenuCode,
                  nameKR: subItem.NameKOR,
                  subMenu: [],
                  path: '/CS302',
                };
              } else {
                return {
                  isOpen: false,
                  menuCode: subItem.MenuCode,
                  nameKR: subItem.NameKOR,
                  subMenu: subItem.SubMenu.map(childItem => {
                    // [TAG] 3번째 메뉴
                    if (childItem.MenuCode === 'AC103') {
                      // AC103 직원 근무
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        // path: '/workStatusEmployee',
                        path: '/AC103',
                      };
                    }
                    if (childItem.MenuCode === 'AR102') {
                      // AR102 스마트 근무내역
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        // path: '/workHistory',
                        path: '/AR102',
                      };
                    }
                    if (childItem.MenuCode === 'AR103') {
                      // AR103 스마트 근무일지
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        // path: '/workLog',
                        path: '/AR103',
                      };
                    }
                    if (childItem.MenuCode === 'CS104') {
                      // CS104 직원 계약
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        // path: '/employeeContract',
                        path: '/CS104',
                      };
                    }
                    if (childItem.MenuCode === 'CV102') {
                      // CV102 차량 등록 관리
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        path: '/CV102',
                      };
                    }
                    if (childItem.MenuCode === 'CV103') {
                      // CV103 차량 주차 관리
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        path: '/CV103',
                      };
                    }
                    if (childItem.MenuCode === 'SE103') {
                      // SE103 직원 정보
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        // path: '/employee',
                        path: '/SE103',
                      };
                    }
                    if (childItem.MenuCode === 'SS102') {
                      // SS102 단지 정보
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        // path: '/apartmentInfo',
                        path: '/SS102',
                      };
                    }
                    if (childItem.MenuCode === 'SA201') {
                      // SA201 근무 구역 설정
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        // path: '/workAreaSetting',
                        path: '/SA201',
                      };
                    }
                    if (childItem.MenuCode === 'SP102') {
                      // SP102(COMMON) 결재 관리
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                        subMenu: [],
                        // path: '/approvalTableSetting',
                        path: '/SP102',
                      };
                    } else {
                      return {
                        menuCode: childItem.MenuCode,
                        nameKR: childItem.NameKOR,
                      };
                    }
                  }),
                };
              }
            }
          ),
        };
      }
    });
  }

  processingMenuData();

  const [filteredMenuArray, setFilteredMenuArray] = useState(filteredMenu);
  // console.log('사이드바 - filteredMenuArray: ', filteredMenuArray);

  const handleCategoryClick = (major: { menuCode: string }, index1: number) => {
    setFilteredMenuArray(menu => {
      return menu.map(item => {
        if (item.menuCode === major.menuCode) {
          return {
            ...item,
            isOpen: !item.isOpen,
          };
        } else {
          return {
            ...item,
            isOpen: item.isOpen,
          };
        }
      });
    });
  };

  function hasPathInMajor(major: { path: string; subMenu: any }) {
    if (major.path) {
      return true;
    }
    if (major.subMenu) {
      return major.subMenu.some((sub: any) => hasPathInMajor(sub));
    }
    if (major.subMenu) {
      return major.subMenu.map((sub: any[]) => {
        sub.some((minimum: any) => hasPathInMajor(minimum));
      });
    }
    return false;
  }

  function hasPathInSub(sub: { nameKR?: any; path: string; subMenu?: any }) {
    if (sub.path) {
      return true;
    }
    if (sub.subMenu) {
      return sub.subMenu.some((minimum: any) => hasPathInSub(minimum));
    }
    return false;
  }

  function hasPathInMinimum(minimum: { path: any }) {
    if (!minimum.path) {
      return false;
    }
    if (minimum.path) {
      return true;
    }
  }

  function onClickCategory(menu: { path: string }) {
    closeSidebar();
    navigate(`${menu.path}`);
  }

  if (location.pathname === '/login') return null;

  return (
    <Background
      className={`background-${isSidebarOpen ? 'open' : ''}`}
      onClick={closeSidebar}
    >
      <Section
        className={`sidebar-${isSidebarOpen ? 'open' : ''}`}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {/* 👇 해당 코드들로 바꿀 것임 */}
        <Wrap>
          <MenuListUl>
            {filteredMenuArray.map((major, index1) => (
              <>
                <MajorMenuLi key={major.nameKR}>
                  {/* ✅ 분기 처리 -----start */}
                  {hasPathInMajor(major) ? (
                    <MajorMenuBox
                      className="on-path"
                      onClick={() => handleCategoryClick(major, index1)}
                    >
                      {major.menuCode === 'CM000' && (
                        // CM000 계약 정보
                        <div className="icon">
                          <FeedIcon />
                        </div>
                      )}
                      {major.menuCode === 'AC000' && (
                        // AC000 근무
                        <div className="icon">
                          <WatchLaterIcon />
                        </div>
                      )}
                      {major.menuCode === 'ER000' && (
                        // ER000 교육
                        <div className="icon">
                          <ModeEditIcon />
                        </div>
                      )}
                      {major.menuCode === 'CH000' && (
                        // CH000 민원
                        <div className="icon">
                          <ForumIcon />
                        </div>
                      )}
                      {major.menuCode === 'CC000' && (
                        // CC000 비용 청구
                        <div className="icon">
                          <AutoFixHighIcon />
                        </div>
                      )}
                      {major.menuCode === 'CS000' && (
                        // CS000 급여/근로
                        <div className="icon">
                          <PaymentIcon />
                        </div>
                      )}
                      {major.menuCode === 'RM000' && (
                        // RM000 자산
                        <div className="icon">
                          <LeaderboardIcon />
                        </div>
                      )}
                      {major.menuCode === 'SE000' && (
                        // SE000 설정
                        <div className="icon">
                          <SettingsIcon />
                        </div>
                      )}

                      {major.nameKR}
                      {/* <p>{filteredMenuArray[index1].isOpen ? ' ▲' : ' ▼'}</p> */}
                      {/* filteredMenuArray[index1].isOpen ? (
                        <p className="open" />
                      ) : (
                        <p className="close" />
                      ) */}
                      {filteredMenuArray[index1].isOpen ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </MajorMenuBox>
                  ) : (
                    <MajorMenuBox className="none-path">path 없음</MajorMenuBox>
                  )}
                  {/* ✅ 분기 처리 -----end */}
                </MajorMenuLi>

                {filteredMenuArray[index1].isOpen &&
                  major.subMenu.map(
                    (
                      sub: { nameKR?: any; path: any; subMenu?: any },
                      idx: number
                    ) => (
                      <>
                        {/* ✅ 분기 처리 -----start */}
                        {hasPathInSub(sub) ? (
                          <SubMenuBox
                            key={idx}
                            className={sub.path ? 'on-path' : ''}
                            onClick={sub.path && (() => onClickCategory(sub))}
                          >
                            <SubMenuUl>
                              <>{sub.nameKR}</>
                            </SubMenuUl>
                          </SubMenuBox>
                        ) : (
                          <SubMenuBox className="none-path">
                            path 없음
                          </SubMenuBox>
                        )}
                        {/* ✅ 분기 처리 -----end */}

                        {sub.subMenu.length > 0 &&
                          sub.subMenu.map(
                            (
                              minimum: { path: any; nameKR?: any },
                              idx: number
                            ) => (
                              <>
                                {/* ✅ 분기 처리 -----start */}
                                {hasPathInMinimum(minimum) ? (
                                  <MinimumMenuBox
                                    key={idx}
                                    className={minimum.path ? 'on-path' : ''}
                                    onClick={
                                      minimum.path &&
                                      (() => {
                                        onClickCategory(minimum);
                                      })
                                    }
                                  >
                                    <MinimumMenuLi>
                                      {minimum.nameKR}
                                    </MinimumMenuLi>
                                  </MinimumMenuBox>
                                ) : (
                                  <MinimumMenuBox className="none-path">
                                    path 없음
                                  </MinimumMenuBox>
                                )}
                                {/* ✅ 분기 처리 -----end */}
                              </>
                            )
                          )}
                      </>
                    )
                  )}
              </>
            ))}
          </MenuListUl>
        </Wrap>
        {/* 👆 해당 코드들로 바꿀 것임*/}
      </Section>
    </Background>
  );
};

const Background = styled.div`
  ${({ theme }) => theme.stopDrag};

  &.background-open {
    position: fixed;
    display: flex;
    top: 55px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;

    @media (min-width: 900px) {
      /**
        * [MEMO] visibility적용::
        * 브라우저 width가 900px이 넘을 경우(min-width: 900px), 배경 숨기기
        */
      visibility: hidden;
    }
  }
`;

const Section = styled.div`
  position: fixed;
  width: 250px;
  height: calc(100vh - 55px); /* [MEMO] 네브바의 height 감안 */
  color: white;
  z-index: 100;
  background-color: ${({ theme }) => theme.backgroundColor.darkGray};
  transition: 0.2s ease-out;

  &.sidebar-open {
    visibility: visible;
    margin-left: 0;

    @media (max-width: 900px) {
    }
  }

  @media (max-width: 900px) {
    margin-left: -250px; /* [MEMO] 사이드바 사라짐 */
    transition: 0.2s ease-out;
  }
`;

const Wrap = styled.nav`
  overflow-x: hidden; /* [MEMO] ✅ 사이드바 스크롤 x축 */
  overflow-y: auto; /* [MEMO] ✅ 사이드바 스크롤 y축 */
`;

const MenuListUl = styled.ul`
  height: 100%; /* [MEMO] ✅ 여기가 있어야 사이드바에 스크롤 생성 가능 */
  font-size: 14px;
`;

const MajorMenuBox = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding: 1rem 25px;
  background-color: ${({ theme }) => theme.mainColor.main};
  // background-color: #575757;
  background-color: #4d4d4d;
  cursor: pointer;

  div {
    &.icon {
      margin-right: 10px;
    }
  }

  p {
    margin-left: 10px;

    &.open {
      width: 0px;
      height: 0px;
      border-bottom: calc(4px * 1.732) solid white;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }

    &.close {
      width: 0px;
      height: 0px;
      border-bottom: calc(4px * 1.732) solid white;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      transform: rotate(180deg);
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.mainColor.main};
  }

  &.none-path {
    display: none;
  }
`;

const MajorMenuLi = styled.li`
  //
`;

const SubMenuBox = styled.div`
  display: block;
  height: 45px;
  padding: 1rem 25px;
  background-color: ${({ theme }) => theme.backgroundColor.darkGray};

  &.on-path {
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.main};
    }
  }

  &.none-path {
    display: none;
  }
`;

const SubMenuUl = styled.ul`
  //
`;

const MinimumMenuBox = styled.div`
  display: block;
  height: 45px;
  padding: 1rem 25px;
  background-color: ${({ theme }) => theme.backgroundColor.darkGray};

  &.on-path {
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.main};
    }
  }

  &.none-path {
    display: none;
  }
`;

const MinimumMenuLi = styled.li`
  position: relative;
  margin-left: 10px;

  :before {
    // content: '-';
    position: absolute;
    width: 100%;
    height: 100%;
    left: -10px;
  }
`;
