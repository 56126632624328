import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ko } from 'date-fns/esm/locale';
import { addDays, subDays } from 'date-fns';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';

import { onApartmentContractInfo } from '../utils/onApartmentContractInfo';
import { onLoadBtn } from '../utils/onLoadBtn';

import { Modal } from '../../apartmentContractInfoSelectModal/Modal';
import { Button } from '../../UI/Button/Long';
import { LogSection } from '../LogSection/LogSection';

import { WorkLogListPrinting } from '../WorkLogListPrinting/WorkLogListPrinting';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

export const Contents = () => {
  const permitCode = sessionStorage.getItem('permitCode');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 관리소장 로그인시 아파트 계약 인덱스
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  const [logListData, setLogListData] = useState([]);

  const [startDate, setStartDate] = useState(new Date()); // [MEMO] Date Picker 라이브러리
  const [endDate, setEndDate] = useState(new Date()); // [MEMO] Date Picker 라이브러리

  // 👇 데이터 피커에서 선택한 날짜 (시작일)
  const pickStartYear = startDate.getFullYear().toString();
  const pickStartMonth = (startDate.getMonth() + 1).toString();
  const pickStartDate = startDate.getDate().toString();

  const calStartYear = String(pickStartYear).padStart(2, '0');
  const calStartMonth = String(pickStartMonth).padStart(2, '0');
  const calStartDate = String(pickStartDate).padStart(2, '0');

  // 👇 데이터 피커에서 선택한 날짜 (종료일)
  const pickEndYear = endDate.getFullYear().toString();
  const pickEndMonth = (endDate.getMonth() + 1).toString();
  const pickEndDate = endDate.getDate().toString();

  const calEndYear = String(pickEndYear).padStart(2, '0');
  const calEndMonth = String(pickEndMonth).padStart(2, '0');
  const calEndDate = String(pickEndDate).padStart(2, '0');

  // const selectedContractStart = new Date(
  //   `${calStartYear}-${calStartMonth}-${calStartDate} 00:00:00`
  // ).toISOString();
  const selectedContractStart = `${calStartYear}-${calStartMonth}-${calStartDate}`;
  const selectedContractEnd = `${calEndYear}-${calEndMonth}-${calEndDate}`;

  const startDateForPrinting = `${calStartYear}년 ${calStartMonth}월 ${calStartDate}일`;
  const endDateForPrinting = `${calEndYear}년 ${calEndMonth}월 ${calEndDate}일`;

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [apartmentListContractData, setApartmentListContractData] = useState<
    any[]
  >([]); // MEMO: 아파트 계약 정보 API
  // const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentContractIdx, setApartmentContractIdx] = useState(''); // 아파트 계약 인덱스
  const [apartmentName, setApartmentName] = useState('');

  const closeModal = () => {
    setOnModal(false);
  };

  // console.log('[AR10-030]Contents - logListData: ', logListData);
  // console.log('[AR10-030]Contents - apartmentContractIdx: ', apartmentContractIdx);

  const [logDataLoading, setLogDataLoading] = useState(false); // [MEMO] 데이터 로딩

  const [workLogListPrintingModal, setWorkLogListPrintingModal] =
    useState(false);

  const closeWorkLogListPrintingModal = () => {
    setWorkLogListPrintingModal(false);
  };

  const onWorkLogListPrintingModal = () => {
    setWorkLogListPrintingModal(true);
  };

  // 👇 [TAG] 권한에 따른 UI 변경 관련
  function onPermitCodeView() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 계약 단지 선택
      useEffect(() => {
        onApartmentContractInfo({
          apartmentListContractData,
          setApartmentListContractData,
          setOnModal,
        });
      }, []);
    }

    useEffect(() => {
      // 근무 내역 불러오기
      if (apartmentContractIdx) {
        onLoadBtn({
          logListData,
          setLogListData,
          selectedContractStart,
          selectedContractEnd,
          apartmentContractIdx,
          setLogDataLoading,
        });
      }
    }, [selectedContractStart, selectedContractEnd, apartmentContractIdx]);
    console.log('apartmentContractIdx: ', apartmentContractIdx);
  }
  onPermitCodeView();

  function onClickedButton(item) {
    setApartmentContractIdx(item.idx);
    setApartmentName(item.Name);
  }
  // [TAG] 👆 권한에 따른 UI 변경 관련

  return (
    <>
      {workLogListPrintingModal && (
        <WorkLogListPrinting
          closeWorkLogListPrintingModal={closeWorkLogListPrintingModal}
          logListData={logListData}
          startDateForPrinting={startDateForPrinting}
          endDateForPrinting={endDateForPrinting}
          apartmentName={apartmentName}
        />
      )}
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListContractData={apartmentListContractData}
          // setApartmentListData={setApartmentListData}
          setApartmentListContractData={setApartmentListContractData}
          apartmentContractIdx={apartmentContractIdx}
          setApartmentContractIdx={setApartmentContractIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      <Title>스마트 근무 일지</Title>

      <Section>
        <DatePickerSection>
          <DatePickerBoxWrap>
            <DatePickerBox>
              <p>시작일</p>
              <div>
                <DatePicker
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  onChange={(date: React.SetStateAction<any>) =>
                    setStartDate(date)
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </DatePickerBox>
            <DatePickerBox>
              <p className="end-date">종료일</p>
              <div>
                <DatePicker
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  selected={endDate}
                  onChange={(date: React.SetStateAction<any>) =>
                    setEndDate(date)
                  }
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  //
                  maxDate={addDays(startDate, 30)}
                />
              </div>
            </DatePickerBox>

            {permitCode === headOfficerManagerCode && (
              <div
                style={{
                  display: 'flex',
                  // marginRight: '5px',
                  marginLeft: '5px',
                }}
              >
                <Button
                  label="계약 단지 선택"
                  onClick={() =>
                    onApartmentContractInfo({
                      apartmentListContractData,
                      setApartmentListContractData,
                      setOnModal,
                    })
                  }
                />

                <ApartmentNameBox>
                  <ApartmentIdx style={{ marginRight: '5px' }}>
                    {apartmentContractIdx}
                  </ApartmentIdx>
                  <ApartmentName>{apartmentName}</ApartmentName>
                </ApartmentNameBox>
              </div>
            )}

            {permitCode === apartmentManagerCode &&
              apartmentListContractData.map((item, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => {
                      onClickedButton(item);
                    }}
                    style={{ fontSize: '13px' }}
                  >
                    <PrintBtn
                      style={{
                        fontSize: '13px',
                        marginLeft: '5px',
                      }}
                    >
                      {item.JobGroupEtc}
                    </PrintBtn>
                  </div>
                );
              })}
          </DatePickerBoxWrap>
          {/* <Input placeholder="근무자" onChange={onEnteredEmployeeName} /> */}

          <PrintBtnSection>
            {/* <p style={{ marginRight: '5px' }}>
              <Button
                label="근무 내역 불러오기"
                onClick={() =>
                  onLoadBtn({
                    logListData,
                    setLogListData,
                    selectedContractStart,
                    selectedContractEnd,
                    apartmentContractIdx,
                    setLogDataLoading,
                  })
                }
              />
            </p> */}

            {/* <PrintBtn onClick={() => onWorkLogListPrintingModal()}>
              근무 일지 인쇄
            </PrintBtn> */}
          </PrintBtnSection>
        </DatePickerSection>

        {/* <Bar /> */}
        {/* <LoadInfoSection> */}
        {/* <LoadBtnSection>
          {permitCode === headOfficerManagerCode && (
              <div
                style={{
                  display: 'flex',
                  marginRight: '5px',
                  marginLeft: '5px',
                }}
              >
                <Button
                  label="계약 단지 선택"
                  onClick={() =>
                    onApartmentContractInfo({
                      apartmentListContractData,
                      setApartmentListContractData,
                      setOnModal,
                    })
                  }
                />
              </div>

               <Button
                label="근무 내역 불러오기"
                onClick={() =>
                  onLoadBtn({
                    logListData,
                    setLogListData,
                    selectedContractStart,
                    selectedContractEnd,
                    apartmentContractIdx,
                    setLogDataLoading,
                  })
                }
              />
              <LoadBtnSection>

              <ApartmentNameBox>
                <ApartmentIdx style={{ marginRight: '5px' }}>
                  {apartmentContractIdx}
                </ApartmentIdx>
                <ApartmentName>{apartmentName}</ApartmentName>
              </ApartmentNameBox>
          )} */}
        {/* </LoadInfoSection> */}

        <LoadedDataSection>
          <LogSection
            logListData={logListData}
            logDataLoading={logDataLoading}
          />
        </LoadedDataSection>
      </Section>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const Section = styled.div`
  /* */
`;

const DatePickerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
`;

const DatePickerBoxWrap = styled.div`
  display: flex;
`;

const DatePickerBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  p {
    /* width: 100%; */
    margin-right: 5px;

    &.end-date {
      margin-left: 10px;
    }
  }
`;

const PrintBtnSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrintBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};
`;

const LoadInfoSection = styled.div`
  display: flex;
  padding: 0 30px;
  height: 40px;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;

const ApartmentName = styled.div`
  /* font-weight: 600; */
`;

const LoadedDataSection = styled.div`
  /* padding: 20px; */
  /* background-color: gray; */
`;
