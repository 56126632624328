// CV10-020 차량 등록 관리 - 서버에 있는 데이터 가져오기
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadExistingDataProps {
  setLoadedData: Dispatch<SetStateAction<never[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  apartmentIdx: number;
  selectedDate: string; // 만료일
  significantFlagSign: string; // 특이사항
  apartmentBlockNumber: string; // Addr1(동)
  apartmentUnitNumber: string; // Addr2(호)
  enteredCarNumber: string; // 차량 번호
  enteredName: string; // 소유주 이름/연락처
  onSavedEnteredName: string; // 🙏 저장용 소유주 이름/연락처
  onSavedEnteredCarNumber: string; // 🙏 저장용 차량 번호
}

export const loadExistingData = async ({
  setLoadedData,
  setLoading,
  apartmentIdx,
  selectedDate, // 만료일
  significantFlagSign, // 특이사항
  apartmentBlockNumber, // Addr1(동)
  apartmentUnitNumber, // Addr2(호)
  enteredCarNumber, // 차량 번호
  enteredName, // 소유주 이름/연락처
  onSavedEnteredName, // 저장용 소유주 이름/연락처
  onSavedEnteredCarNumber, // 저장용 차량 번호
}: loadExistingDataProps) => {
  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
    return alert('단지를 선택해주세요');
  }

  setLoading(true);

  let currentApartmentRefIdx = '';
  let flag = '';
  let Addr1 = '';
  let Addr2 = '';
  let owner = '';
  let carNumber = '';

  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  if (significantFlagSign) {
    // &Flag=M
    flag = `&Flag=${significantFlagSign}`;
  }

  if (apartmentBlockNumber) {
    // &Addr1=888
    Addr1 = `&Addr1=${apartmentBlockNumber}`;
  }

  if (apartmentUnitNumber) {
    // &Addr2=999
    Addr2 = `&Addr2=${apartmentUnitNumber}`;
  }

  if (enteredCarNumber) {
    // &CarNo=%EC%B0%A8%EB%9F%89%EB%B2%88%ED%98%B8
    carNumber = `&CarNo=${enteredCarNumber}`;
  }

  if (enteredName) {
    // &Owner=%EC%86%8C%EC%9C%A0%EC%A3%BC
    owner = `&Owner=${enteredName}`;
  }

  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/vehicle/cv10020/regcarmanage/1?ItemPerPage=0&ItemStartPage=0&ExpireDate=20231026
      `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}?ItemPerPage=0&ItemStartPage=0&ExpireDate=${selectedDate}${flag}${Addr1}${Addr2}${carNumber}${owner}`,
      // `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${apartmentIdx}?ItemPerPage=0&ItemStartPage=0&ExpireDate=20231026`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      try {
        if (res.data.ErrorCode === 0) {
          sessionStorage.setItem('savedEnteredName', onSavedEnteredName); // 🙏 소유주 이름/연락처 (인풋에 남기기 위한)
          sessionStorage.setItem(
            'savedEnteredCarNumber',
            onSavedEnteredCarNumber
          ); // 🙏 차량 번호

          setLoading(false);
          setLoadedData(res.data.Data);

          console.log(
            'CV10-020 불러오기 API: ',
            `${currentApartmentRefIdx}...생략...&ExpireDate=${selectedDate}${flag}${Addr1}${Addr2}${carNumber}${owner}`
          );
        }
        incorrectToken(res);
      } catch (e) {
        console.error(e);
      }
    });
};
