// CV10-020 차량 등록 관리 - 서버로 '신규' 데이터 보내기
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface uploadNewDataProps {
  apartmentIdx: number;

  resultData: any[];
  isCheckedSync: boolean;

  setLoading: Dispatch<SetStateAction<boolean>>;
  setOpenBeforeResistGuideModal: Dispatch<SetStateAction<boolean>>;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>;
}

export const uploadNewData = async ({
  apartmentIdx,

  resultData,
  isCheckedSync,

  setLoading,
  setOpenBeforeResistGuideModal,
  setDetectChangingData,
}: uploadNewDataProps) => {
  setLoading(true);

  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
    return alert('단지를 선택해주세요');
  }

  let currentApartmentRefIdx = '';

  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  await axios({
    // https://dev.mmigroup.co.kr:8020/vehicle/cv10020/regcarmanage/1
    url: `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}`,
    method: 'post',
    headers: { tokenId: token },
    data: {
      Data: resultData.map(data => {
        return {
          RegEmployeeNo: data.RegEmployeeNo
            ? data.RegEmployeeNo.toString()
            : '',
          Addr1: data.Addr1 ? data.Addr1.toString() : '',
          Addr2: data.Addr2 ? data.Addr2.toString() : '',
          OwnerName: data.OwnerName ? data.OwnerName : '',
          OwnerPhone: data.OwnerPhone ? data.OwnerPhone.toString() : '',
          CarNo: data.CarNo,
          CarFlag: data.CarFlag ? data.CarFlag : '',
          CarExpireDate: data.CarExpireDate ? data.CarExpireDate : '',
        };
      }),
    },
  }).then(res => {
    console.log(
      '📢url: ',
      `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}`
    );
    console.log('👍uploadNewData.tsx-resultData: ', resultData);
    console.log('👍res.data: ', res.data);

    try {
      if (res.data.ErrorCode === 0) {
        setLoading(false);
        alert('데이터가 성공적으로 저장되었습니다');
        setOpenBeforeResistGuideModal(false);
        setDetectChangingData(true);
      } else if (res.data.ErrorCode !== 0) {
        setLoading(false);
        alert(`${res.data.ErrorMsg}`);
        setOpenBeforeResistGuideModal(false);
      }
      incorrectToken(res);
    } catch (e) {
      console.error(e);
    }
  });
};
