// CV10-020 차량 등록 관리 - 데이터 필터링(데이터 분류: 신규, 업데이트)
import React from 'react';
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface checkDuplicateDataProps {
  xlsxFileData: any[];
  loadedData: any[];
  processedAddedData: any[];
  processedData: any[];

  setFilteredNewAddedData: Dispatch<SetStateAction<[]>>;
  setModifiedData: Dispatch<SetStateAction<[]>>;
  setCompletelyExistentData: Dispatch<SetStateAction<[]>>;
  setOnlyExistentServerData: Dispatch<SetStateAction<[]>>;
  setResultData: Dispatch<SetStateAction<[]>>;
  setResultData2: Dispatch<SetStateAction<[]>>;
}

export const checkDuplicateCarNum = ({
  xlsxFileData,
  loadedData,
  processedAddedData,
  processedData, // 미사용(보류)
  setFilteredNewAddedData,
  setModifiedData,
  setCompletelyExistentData,
  setOnlyExistentServerData,
  setResultData,
  setResultData2,
}: checkDuplicateDataProps) => {
  /**
   * 1. filteredNewAddedInfo: 신규 데이터
   * -서버와 로컬 데이터간 일치하는 차량 번호(CarNo)가 없는 경우에서 추출(=newAddedInfo 변수).
   * -(서버에는 없는) 로컬에만 있는 데이터
   * -newAddedInfo 변수에서 중복된 CarNo를 제거한 데이터 변수가 filteredNewAddedInfo.
   * 2. modifiedInfo: 업데이트 되는 데이터
   * -서버와 로컬 데이터간 차량 번호(CarNo) 일치 + 차량 번호 외 값들 중 일치하지 않는(=변경된) 값이 있는 경우.
   * -기존 서버에 저장된 데이터들은 modifiedInfo의 데이터로 변경됨.
   * 3. completelyExistentInfo: 이미 서버에 존재하는 데이터
   * -=서버 데이터와 로컬 데이터가 완전히 일치하는 경우.
   * -서버와 로컬 데이터간 차량 번호(CarNo) 일치 + 차량 번호외 값들까지 모두 일치하는 경우.
   * 4. onlyExistentServerInfo: 동기화로 인해 삭제될 데이터
   * -서버와 로컬 데이터간 일치하는 차량 번호(CarNo)가 없는 경우에서 추출.
   * -(로컬에는 없는) 서버에만 있는 데이터
   */

  console.log(
    `1. filteredNewAddedInfo: 신규 데이터
  -서버와 로컬 데이터간 일치하는 차량 번호(CarNo)가 없는 경우(=newAddedInfo 변수).
  -newAddedInfo 변수에서 중복된 CarNo를 제거한 데이터 변수가 filteredNewAddedInfo.`
  );
  console.log(
    `2. modifiedInfo: 업데이트 되는 데이터
  -서버와 로컬 데이터간 차량 번호(CarNo) 일치 + 차량 번호 외 값들 중 일치하지 않는(=변경된) 값이 있는 경우.
  -기존 서버에 저장된 데이터들은 modifiedInfo의 데이터로 변경됨.`
  );
  console.log(
    `3. completelyExistentInfo: 이미 서버에 존재하는 데이터
  -=서버 데이터와 로컬 데이터가 완전히 일치하는 경우.
  -서버와 로컬 데이터간 차량 번호(CarNo) 일치 + 차량 번호외 값들까지 모두 일치하는 경우.`
  );

  // 차량 번호가 이미 존재하는(=기존 불러온 데이터와 중복인) 데이터들
  // -> (차량 번호 외) 다른 요소들 중 완전 일치 or 하나라도 불일치 여부 파악
  // -> 1) 차량 번호 외에도 전부 일치하면 -> 해당 데이터 삭제
  // -> 2) 불일치하는 요소가 하나라도 있으면 -> 업데이트용 데이터

  // 👇 ---------- 미정리 -----start
  // const resultFoo = loadedData.filter(loadedItem => {
  //   // console.log('🥶loadedItem.CarNo: ', loadedItem.CarNo);

  //   return addedData.forEach(addedItem => {
  //     // console.log('🥶🥶addedItem.CarNo: ', addedItem.CarNo);

  //     console.log(
  //       '🥶🥶🥶loadedItem.CarNo !== addedItem.CarNo: ',
  //       loadedItem.CarNo !== addedItem.CarNo
  //     );
  //     // console.log('🥶🥶🥶resultFoo: ', resultFoo);
  //   });
  // });

  /*
  const resultFoo = [];
  loadedData.forEach(loadedItem => {
    let isDuplicate = false; // 클로저 변수

    addedData.forEach(addedItem => {
      if (loadedItem.CarNo === addedItem.CarNo) {
        isDuplicate = true; // 클로저 변수 업데이트
      }
    });

    if (!isDuplicate) {
      resultFoo.push(loadedItem); // 중복되지 않는 경우에만 결과 배열에 추가
    }
  });
  */
  // 👆 ---------- 미정리 -----end

  console.log('⭐checkDuplicateCarNum.tsx/📢loadedData: ', loadedData);
  console.log(
    '⭐checkDuplicateCarNum.tsx/📢processedAddedData: ',
    processedAddedData
  );

  /**
   * [MEMO] newAddedInfo와 onlyExistentServerInfo의 차이점:
   * -newAddedInfo는 (로컬-서버간 차량 번호가 일치하지 않는 데이터중) 로컬 데이터를 저장하는 변수,
   * -onlyExistentServerInfo는 (로컬-서버간 차량 번호가 일치하지 않는 데이터중) 서버 데이터를 저장하는 변수.
   * -둘 다 로컬-서버간 차량 번호가 일치하지 않는 데이터라는 공통점이 있으나,
   * 로컬과 서버 둘 중 어디를 중점으로 저장했는 지가 다름.
   */
  /**
   * [MEMO] newAddedInfo:
   * -(서버에는 없는) 로컬에서 추가한 데이터
   * -서버와 로컬 데이터간 일치하는 차량 번호(CarNo)가 없는 경우에서 추출함
   */
  const newAddedInfo = processedAddedData.filter(addedItem => {
    /**
     * [MEMO] !loadedData.some(...):
     * loadedItem.CarNo와 addedItem.CarNo가 일치하지 않는 경우에만 반환
     * (=서버에 일치하는 addedItem.CarNo가 없는 경우에만 반환)
     */
    return !loadedData.some(loadedItem => addedItem.CarNo === loadedItem.CarNo);
  });

  // 👇 구현중 --------------------start
  /**
   * [MEMO] onlyExistentServerInfo:
   * -(로컬에는 없는) 서버에만 있는 데이터
   * -서버와 로컬 데이터간 일치하는 차량 번호(CarNo)가 없는 경우에서 추출함
   */

  const onlyExistentServerInfo = loadedData.filter(loadedItem => {
    /**
     * [MEMO] processedAddedData.some(...):
     * -loadedItem.CarNo와 addedItem.CarNo가 일치하는 경우에 반환
     */
    // return processedAddedData.some(
    //   addedItem => loadedItem.CarNo !== addedItem.CarNo
    // );
    return !processedAddedData.some(
      addedItem => addedItem.CarNo === loadedItem.CarNo
    );
  });

  setOnlyExistentServerData(onlyExistentServerInfo);
  console.log('🚀🚀🚀onlyExistentServerInfo', onlyExistentServerInfo);
  // 👆 구현중 --------------------end

  /**
   * [MEMO] filteredNewAddedInfo:
   * -신규 데이터
   * -newAddedInfo(추가한 로컬 데이터)에서 중복된 CarNo를 제거한 데이터.
   * -로컬상에서 중복된 CarNo이 있을 경우,
   * filteredNewAddedInfo 변수로 저장하는 과정에서 중복이 제거 됨.
   */
  const filteredNewAddedInfo = [
    ...new Set(newAddedInfo.map(item => item.CarNo)),
  ].map(CarNo => newAddedInfo.find(item => item.CarNo === CarNo));

  setFilteredNewAddedData(filteredNewAddedInfo);

  // console.log('checkDuplicateCarNum.tsx/newAddedInfo: ', newAddedInfo);
  console.log(
    '⭐checkDuplicateCarNum.tsx/📢filteredNewAddedInfo: ',
    filteredNewAddedInfo
  );

  /**
   * [MEMO] existentCarNumData:
   * -로컬 데이터의 차량 번호(CarNo)와 서버에 있는 데이터의 차량 번호(CarNo)가
   * 일치하는 경우의 데이터.
   */
  const existentCarNumData = processedAddedData.filter(addedItem => {
    /**
     * [MEMO] processedAddedData.some(...):
     * -loadedItem.CarNo와 addedItem.CarNo가 일치하는 경우에 반환
     */
    return loadedData.some(loadedItem => addedItem.CarNo === loadedItem.CarNo);
  });

  // console.log(
  //   '⭐checkDuplicateCarNum.tsx/📢로컬데이터차량번호=서버데이터차량번호(업데이트체크전)-existentCarNumData: ',
  //   existentCarNumData
  // );

  /**
   * completelyExistentInfo:
   * -이미 서버에 존재하는 데이터(=서버 데이터와 로컬 데이터가 완전히 겹치는 데이터=서버가 이미 가지고 있는 데이터).
   * -서버와 로컬 데이터간 차량 번호(CarNo) 일치 + 차량 번호외 값들까지 모두 일치하는 경우.
   */
  let completelyExistentInfo = [];
  function onCompletelyExistentInfo() {
    completelyExistentInfo = loadedData.filter(loadedItem => {
      return processedAddedData.some(
        addedItem =>
          loadedItem.CarNo === addedItem.CarNo &&
          loadedItem.Addr1 === addedItem.Addr1 &&
          loadedItem.Addr2 === addedItem.Addr2 &&
          loadedItem.CarFlag === addedItem.CarFlag &&
          loadedItem.CarExpireDate.slice(0, 10) ===
            addedItem.CarExpireDate.slice(0, 10) &&
          loadedItem.OwnerName === addedItem.OwnerName &&
          loadedItem.OwnerPhone === addedItem.OwnerPhone
      );
    });

    setCompletelyExistentData(completelyExistentInfo);
    console.log(
      '⭐checkDuplicateCarNum.tsx/📢completelyExistentInfo: ',
      completelyExistentInfo
    );
  }

  /**
   * modifiedInfo
   * -업데이트 되는 데이터
   * -서버와 로컬 데이터간 차량 번호(CarNo) 일치 + 차량 번호 외 값들 중 일치하지 않는(=변경된) 값이 있는 경우.
   * -기존 서버에 저장된 데이터들은 modifiedInfo의 데이터로 변경됨.
   */
  let modifiedInfo = [];
  function onModifiedInfo() {
    modifiedInfo = existentCarNumData.map(existentItem => {
      const matchingItem = processedAddedData.find(
        addedItem => addedItem.CarNo === existentItem.CarNo
      );

      // if (matchingItem) {
      //   return {
      //     ...existentItem,
      //     Addr1:
      //       existentItem.Addr1 !== matchingItem.Addr1
      //         ? matchingItem.Addr1 // 새로운 Addr1
      //         : existentItem.Addr1,
      //     Addr2:
      //       existentItem.Addr2 !== matchingItem.Addr2
      //         ? matchingItem.Addr2 // 새로운 Addr2
      //         : existentItem.Addr2,
      //     CarExpireDate:
      //       existentItem.CarExpireDate !== matchingItem.CarExpireDate
      //         ? matchingItem.CarExpireDate // 새로운 CarExpireDate(만료일)
      //         : existentItem.CarExpireDate,
      //     CarFlag:
      //       existentItem.CarFlag !== matchingItem.CarFlag
      //         ? matchingItem.CarFlag // 새로운 CarFlag
      //         : existentItem.CarFlag,
      //     OwnerName:
      //       existentItem.OwnerName !== matchingItem.OwnerName
      //         ? matchingItem.OwnerName // 새로운 OwnerName
      //         : existentItem.OwnerName,
      //     OwnerPhone:
      //       existentItem.OwnerPhone !== matchingItem.OwnerPhone
      //         ? matchingItem.OwnerPhone // 새로운 OwnerPhone
      //         : existentItem.OwnerPhone,
      //   };
      // }
      // return existentItem;
      if (matchingItem) {
        return {
          ...existentItem,
          // Check and update other properties as needed
        };
      }
      return existentItem;
    });

    const completelyExistentCarNos = completelyExistentInfo.map(
      item => item.CarNo
    );

    const filteredModifiedInfo = modifiedInfo.filter(
      item => !completelyExistentCarNos.includes(item.CarNo)
    );

    setModifiedData(filteredModifiedInfo);
    console.log('⭐checkDuplicateCarNum.tsx/📢modifiedInfo: ', modifiedInfo);
  }

  onCompletelyExistentInfo();
  onModifiedInfo();

  const combinedData = [...filteredNewAddedInfo, ...modifiedInfo];
  const combinedData2 = [
    ...filteredNewAddedInfo,
    ...modifiedInfo,
    ...onlyExistentServerInfo,
  ];
  setResultData(combinedData);
  setResultData2(combinedData2);

  console.log('🍑🍑🍑combinedData: ', combinedData);
};
