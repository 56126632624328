import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onLoadBtnProps {
  setAreaListData: Dispatch<SetStateAction<never[]>>;
  apartmentIdx: string;
}

export const onLoadBtn = async ({
  setAreaListData,
  apartmentIdx,
}: onLoadBtnProps) => {
  const token = sessionStorage.getItem('token');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 아파트 계약 인덱스

  if (!apartmentIdx) {
    return alert('단지를 선택해주세요');
  }

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/setting/sa20010/workingarea/1?TrackingGroup=W
      `${process.env.COMMON_URL}/setting/sa20010/workingarea/${apartmentIdx}?TrackingGroup=W`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '[SA20-010] 근무 구역 설정 리스트 API: ',
      //   `${process.env.COMMON_URL}/setting/sa20010/workingarea/${apartmentIdx}?TrackingGroup=W`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          // console.log(
          //   '[SA20-010] 근무 구역 설정 리스트 불러오기 res.data.Data: ',
          //   res.data.Data
          // );
          setAreaListData(res.data.Data);
          console.log(
            '[SA20-010] 근무 구역 설정 리스트 - res.data.Data: ',
            res.data.Data
          );
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
