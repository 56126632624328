import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onSaveDataProps {
  userList: any[];
  setListData: Dispatch<SetStateAction<any[]>>;
}

export const onSaveData = async ({
  userList,
  setListData,
}: onSaveDataProps) => {
  const token = sessionStorage.getItem('token');
  const approvalIdx = sessionStorage.getItem('apartmentRefIdx');
  // const approvalIdx = '1';

  // console.log('🍓userList: ', userList);

  const nonEmptyCount = userList.filter(
    data => data.ApprovalName !== ''
  ).length;
  // console.log('🍓nonEmptyCount', userList && nonEmptyCount);

  if (userList.length > 0) {
    userList.map(data => {
      if (data.ApprovalName === undefined) {
        for (let i = 0; i < 4; i++) {
          if (data[i].ApprovalName === undefined) {
            return (data[i].ApprovalName = '');
          }
        }
      }
    });
  }

  await axios({
    url: `${process.env.COMMON_URL}/common/approval/approvalwebadmin/${approvalIdx}`,
    method: 'put',
    headers: { tokenId: token },
    data: {
      Count: userList ? nonEmptyCount : '',
      ApprovalWebData: userList.map(data => data),
      // ApprovalWebData: [
      //   {
      //     OrderNo: 2,
      //     ApprovalName: '가나다',
      //   },
      //   {
      //     OrderNo: 0,
      //     ApprovalName: '라마바',
      //   },
      //   {
      //     OrderNo: 1,
      //     ApprovalName: '사아자',
      //   },
      // ],
    },
  }).then(res => {
    // console.log(
    //   'SP10-020 결재선 설정 - 저장버튼: ',
    //   `${process.env.COMMON_URL}/common/approval/approvalwebadmin/${approvalIdx}`
    // );

    if (res.data.ErrorCode === 0) {
      console.log('🍓res.data.Data: ', res.data.Data);
      setListData(res.data.Data);
      alert('결재자 정보가 저장되었습니다');

      incorrectToken(res);
    }

    if (res.data.ErrorCode !== 0) {
      alert(`ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`);
    }
    // console.error(e);
    console.log(
      `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
    );
  });
};
