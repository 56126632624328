import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { onApartmentContractInfo } from '../utils/onApartmentContractInfo'; // ref가 없는 인덱스 API (=단지 계약 번호(고유 번호 아님))
import { Modal } from '../../apartmentContractInfoSelectModal/Modal'; // ref가 없는 인덱스 모달 (=단지 계약 번호(고유 번호 아님))

import { onLoadBtn } from '../utils/onLoadBtn';
import { onChangeLocationList } from '../utils/onChangeLocationList';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

import { Grid } from '../Grid/Grid';

import { Button } from '../../UI/Button/Long';

export const Contents = () => {
  const token = sessionStorage.getItem('token');

  const [areaListData, setAreaListData] = useState([]);
  const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentName, setApartmentName] = useState('');

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API

  const closeModal = () => {
    setOnModal(false);
  };

  const [dataArray, setDataArray] = useState<any[]>([]);
  const [extractedData, setExtractedData] = useState<any[]>([]);

  const handleSave = async () => {
    const extractedData = dataArray.map((data, idx) => ({
      idx: Number(data.dataIdx),
      NewOrderNo: Number(idx),
    }));

    setExtractedData(extractedData);

    if (extractedData.length <= 0) {
      return;
      // return alert('불러온 목록이 없습니다');
    }

    console.log('extractedData: ', extractedData);

    await axios({
      url: `${process.env.COMMON_URL}/setting/sa20010/workingareaorder/${apartmentIdx}?TrackingGroup=W`,
      method: 'put',
      headers: { tokenId: token },
      data: { NewOrderIdx: extractedData },
    }).then(res => {
      // console.log(
      //   '[SA20-010] 근무 구역 설정 순서 수정 API: ',
      //   `${process.env.COMMON_URL}/setting/sa20010/workingareaorder/${apartmentIdx}?TrackingGroup=W`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          alert('변경이 완료되었습니다');
          onLoadBtn({ setAreaListData, apartmentIdx });
          return;
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
  };

  return (
    <>
      {onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListData={apartmentListData}
          setApartmentListData={setApartmentListData}
          apartmentIdx={apartmentIdx}
          setApartmentIdx={setApartmentIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      <Title>근무 구역 설정</Title>

      <Section>
        <LoadInfoSection>
          <LoadBtnSection>
            <div style={{ marginRight: '5px' }}>
              <Button
                label="계약 단지 선택"
                onClick={() =>
                  onApartmentContractInfo({
                    apartmentListData,
                    setApartmentListData,
                    setOnModal,
                  })
                }
              />
            </div>

            <div style={{ marginRight: '5px' }}>
              <Button
                label="구역 목록 불러오기"
                onClick={() =>
                  onLoadBtn({
                    // recordListData,
                    setAreaListData,
                    // selectedContractStart,
                    // selectedContractEnd,
                    apartmentIdx,
                    // setLoading,
                  })
                }
              />
            </div>

            <SaveBtn
              className={`${extractedData.length <= 0 ? 'off' : 'on'}`}
              onClick={() => handleSave()}
            >
              순서 변경 저장하기
            </SaveBtn>
          </LoadBtnSection>

          <ApartmentNameBox>
            {/* <p>단지 이름</p> */}
            <ApartmentIdx style={{ marginRight: '5px' }}>
              {/* {apartmentIdx} */}
            </ApartmentIdx>
            <ApartmentName>{/* {apartmentName} */}</ApartmentName>
          </ApartmentNameBox>
        </LoadInfoSection>

        <LoadedDataSection>
          <Grid
            areaListData={areaListData}
            apartmentName={apartmentName}
            apartmentIdx={apartmentIdx}
            dataArray={dataArray}
            setDataArray={setDataArray}
            extractedData={extractedData}
            setExtractedData={setExtractedData}
          />
        </LoadedDataSection>
      </Section>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const Section = styled.div`
  ${({ theme }) => theme.stopDrag};
`;

const LoadInfoSection = styled.div`
  display: flex;
  /* justify-content: space-between; */
  padding: 0 30px;
  height: 40px;
`;

const LoadBtnSection = styled.div`
  display: flex;
  align-items: center;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;

const ApartmentName = styled.div`
  /* font-weight: 600; */
`;

const LoadedDataSection = styled.div`
  /* padding: 20px; */
  /* background-color: gray; */
`;

const SaveBtn = styled.div`
  ${({ theme }) => theme.printBtnStyle};
  padding: 8px 10px;

  &.on {
    /*  */
  }

  &.off {
    cursor: default;

    :hover {
      background-color: #ededed;
    }
  }
`;
