// CV10-020 차량 주차 관리 - Addr2(호수) 리스트를 불러오는 API

import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadApartmentUnitListProps {
  apartmentIdx: number;
  apartmentBlockNumber: string;
  setApartmentUnitListData: Dispatch<SetStateAction<never[]>>;
  apartmentUnitOptions: { value: any; label: any; block: string }[];
  setCurrentSelectedBlock: Dispatch<SetStateAction<string>>;
}
export const loadApartmentUnitList = async ({
  apartmentIdx,
  apartmentBlockNumber,
  setApartmentUnitListData,
  apartmentUnitOptions,
  setCurrentSelectedBlock,
}: loadApartmentUnitListProps) => {
  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  console.log(
    'loadApartmentUnitList.tsx/호수 목록-apartmentBlockNumber: ',
    apartmentBlockNumber
  );

  let currentApartmentRefIdx = '';
  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/vehicle/cv10010/getaddrlist/1?ItemPerPage=0&ItemStartPage=0&ADDR1=999
      `${process.env.COMMON_URL}/vehicle/cv10010/getaddrlist/${currentApartmentRefIdx}?ItemPerPage=0&ItemStartPage=0&ADDR1=${apartmentBlockNumber}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      try {
        if (res.data.ErrorCode === 0) {
          setApartmentUnitListData(res.data.Data);
          setCurrentSelectedBlock(res.data.Request.ADDR1);
        }
      } catch (e) {
        console.error(e);
      }
    });
};
