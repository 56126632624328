// AR10-020 스마트 근무내역
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { gridOptions } from '../Grid/gridOptions';

import { ko } from 'date-fns/esm/locale';
import { addDays, subDays } from 'date-fns';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';

import { HistorySection } from '../HistorySection/HistorySection';
import { onLoadBtn } from '../utils/onLoadBtn';

import { onApartmentContractInfo } from '../utils/onApartmentContractInfo';
import { Modal } from '../../apartmentContractInfoSelectModal/Modal';
import WorkRecordReportPrinting from '../WorkHistoryReportPrinting/WorkRecordReportPrinting';

import { Button } from '../../UI/Button/Long';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

export const Contents = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const permitCode = sessionStorage.getItem('permitCode');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 관리소장 로그인시 아파트 계약 인덱스
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  const [recordListData, setRecordListData] = useState([]);

  const [startDate, setStartDate] = useState(new Date()); // [MEMO] Date Picker 라이브러리
  const [endDate, setEndDate] = useState(new Date()); // [MEMO] Date Picker 라이브러리

  // 👇 데이터 피커에서 선택한 날짜 (시작일)
  const pickStartYear = startDate.getFullYear().toString();
  const pickStartMonth = (startDate.getMonth() + 1).toString();
  const pickStartDate = startDate.getDate().toString();

  const calStartYear = String(pickStartYear).padStart(2, '0');
  const calStartMonth = String(pickStartMonth).padStart(2, '0');
  const calStartDate = String(pickStartDate).padStart(2, '0');

  // 👇 데이터 피커에서 선택한 날짜 (종료일)
  const pickEndYear = endDate.getFullYear().toString();
  const pickEndMonth = (endDate.getMonth() + 1).toString();
  const pickEndDate = endDate.getDate().toString();

  const calEndYear = String(pickEndYear).padStart(2, '0');
  const calEndMonth = String(pickEndMonth).padStart(2, '0');
  const calEndDate = String(pickEndDate).padStart(2, '0');

  const selectedContractStart = `${calStartYear}${calStartMonth}${calStartDate}`;
  const selectedContractEnd = `${calEndYear}${calEndMonth}${calEndDate}`;

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  // const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API
  const [apartmentListContractData, setApartmentListContractData] = useState<
    any[]
  >([]); // MEMO: 아파트 계약 정보 API
  // const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentContractIdx, setApartmentContractIdx] = useState(''); // 아파트 계약 인덱스
  const [apartmentName, setApartmentName] = useState('');

  const closeModal = () => {
    setOnModal(false);
  };

  // [MEMO] 근무 보고서 인쇄 페이지로 데이터 전송
  const onMoveToPrintPage = () => {
    if (recordListData.length <= 0) {
      return alert('불러온 데이터가 없습니다');
    }

    const dataToSend = 'work-record-report-printing';

    // onLoadBtn({
    //   setLoading,
    //   setRecordListData,
    //   apartmentContractIdx,
    //   selectedContractStart,
    //   selectedContractEnd,
    // });

    const dataObject = {
      apartmentName: apartmentName ? apartmentName : '',
      apartmentContractIdx: apartmentContractIdx,
      selectedContractStart: selectedContractStart,
      selectedContractEnd: selectedContractEnd,
    };

    const url = `/${dataToSend}?dataObject=${encodeURIComponent(
      JSON.stringify(dataObject)
    )}`;

    const childWindow = window.open(
      url,
      '_blank',
      'width=820px, height=1000px, toolbar=no, location=no, status=no'
    );
    if (childWindow) {
      childWindow.postMessage({ dataObject }, window.origin);
    }
  };

  const onPersonalWorkCardModal = () => {
    if (recordListData.length <= 0) {
      return alert('불러온 데이터가 없습니다');
    }

    // navigate('/work-record-report-printing', { state: recordListData }); // 기존 (startDate, endDate없이 단독으로 줄때)
    navigate('/work-record-report-printing', {
      state: {
        recordListData: recordListData,
        calStartYear: calStartYear,
        calStartMonth: calStartMonth,
        calStartDate: calStartDate,
        calEndYear: calEndYear,
        calEndMonth: calEndMonth,
        calEndDate: calEndDate,
      },
    });
  };

  const [loading, setLoading] = useState(false); // [MEMO] 데이터 로딩

  // 💨💨💨💨💨💨💨💨💨💨💨💨💨💨💨
  const selectedApartmentIdxWorkHistory = sessionStorage.getItem(
    'selectedApartmentIdxWorkHistory'
  );
  const selectedApartmentNameIdxWorkHistory = sessionStorage.getItem(
    'selectedApartmentNameIdxWorkHistory'
  );

  const selectedCalStartYear = sessionStorage.getItem('selectedCalStartYear');
  const selectedCalStartMonth = sessionStorage.getItem('selectedCalStartMonth');
  const selectedCalStartDate = sessionStorage.getItem('selectedCalStartDate');
  const selectedCalEndYear = sessionStorage.getItem('selectedCalEndYear');
  const selectedCalEndMonth = sessionStorage.getItem('selectedCalEndMonth');
  const selectedCalEndDate = sessionStorage.getItem('selectedCalEndDate');

  // const selectedContractStart = `${calStartYear}${calStartMonth}${calStartDate}`;
  // const selectedContractEnd = `${calEndYear}${calEndMonth}${calEndDate}`;

  const selectedContractStartPrintPage = `${selectedCalStartYear}${selectedCalStartMonth}${selectedCalStartDate}`;
  const selectedContractEndPrintPage = `${selectedCalEndYear}${selectedCalEndMonth}${selectedCalEndDate}`;

  if (apartmentContractIdx) {
    sessionStorage.setItem(
      'selectedApartmentIdxWorkHistory',
      apartmentContractIdx
    );
  }

  if (apartmentName) {
    sessionStorage.setItem(
      'selectedApartmentNameIdxWorkHistory',
      apartmentName
    );
  }

  if (calStartYear) {
    sessionStorage.setItem(
      'selectedCalStartYear',
      selectedCalStartYear ? selectedCalStartYear : calStartYear
    );
  }

  if (calStartMonth) {
    sessionStorage.setItem(
      'selectedCalStartMonth',
      selectedCalStartMonth ? selectedCalStartMonth : calStartMonth
    );
  }

  if (calStartDate) {
    sessionStorage.setItem(
      'selectedCalStartDate',
      selectedCalStartDate ? selectedCalStartDate : calStartDate
    );
  }

  if (calEndYear) {
    sessionStorage.setItem(
      'selectedCalEndYear',
      selectedCalEndYear ? selectedCalEndYear : calEndYear
    );
  }

  if (calEndMonth) {
    sessionStorage.setItem(
      'selectedCalEndMonth',
      selectedCalEndMonth ? selectedCalEndMonth : calEndMonth
    );
  }

  if (calEndDate) {
    sessionStorage.setItem(
      'selectedCalEndDate',
      selectedCalEndDate ? selectedCalEndDate : calEndDate
    );
  }

  // useEffect(() => {
  //   if (
  //     selectedApartmentIdxWorkHistory &&
  //     // location.pathname === '/workHistory'
  //     location.pathname === '/AR102'
  //   ) {
  //     // 👇 [TAG] 날짜 업데이트 (돌아가기로 나타난 페이지에서도 날짜 반영하게끔)
  //     const newStartDate = new Date(
  //       `${selectedCalStartYear}-${selectedCalStartMonth}-${selectedCalStartDate}`
  //     );
  //     setStartDate(newStartDate);

  //     const newEndDate = new Date(
  //       `${selectedCalEndYear}-${selectedCalEndMonth}-${selectedCalEndDate}`
  //     );
  //     setEndDate(newEndDate);
  //     // 👆 [TAG] 날짜 업데이트 (돌아가기로 나타난 페이지에서도 날짜 반영하게끔)

  //     onLoadBtn({
  //       setLoading,
  //       setRecordListData,
  //       apartmentIdx: selectedApartmentIdxWorkHistory,
  //       selectedContractStart: selectedContractStartPrintPage,
  //       selectedContractEnd: selectedContractEndPrintPage,
  //     });
  //   }
  // }, []);

  // 👇 [TAG] 권한에 따른 UI 변경 관련
  function onPermitCodeView() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 계약 단지 선택
      useEffect(() => {
        onApartmentContractInfo({
          apartmentListContractData,
          setApartmentListContractData,
          setOnModal,
        });
      }, []);
    }

    useEffect(() => {
      // 근무 내역 불러오기
      if (apartmentContractIdx) {
        onLoadBtn({
          setLoading,
          setRecordListData,
          apartmentContractIdx,
          selectedContractStart,
          selectedContractEnd,
        });
      }
    }, [selectedContractStart, selectedContractEnd, apartmentContractIdx]);
    console.log('apartmentContractIdx: ', apartmentContractIdx);
  }
  onPermitCodeView();

  function onClickedButton(item) {
    setApartmentContractIdx(item.idx);
    setApartmentName(item.Name);
  }
  // [TAG] 👆 권한에 따른 UI 변경 관련

  // [MEMO] onExportCSVFile(): CSV파일로 내보내기
  function onExportCSVFile() {
    gridOptions.api.exportDataAsCsv();
  }

  return (
    <>
      {/* 관리자 권한시: 계약 단지 선택 */}
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListContractData={apartmentListContractData}
          setApartmentListContractData={setApartmentListContractData}
          apartmentContractIdx={apartmentContractIdx}
          setApartmentContractIdx={setApartmentContractIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      <Title>스마트 근무 내역</Title>

      <Section>
        <DatePickerSection>
          <DatePickerBoxWrap>
            <DatePickerBox>
              <p>시작일</p>
              <div>
                <DatePicker
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  onChange={(date: React.SetStateAction<any>) =>
                    setStartDate(date)
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </DatePickerBox>
            <DatePickerBox>
              <p className="end-date">종료일</p>
              <div>
                <DatePicker
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  selected={endDate}
                  onChange={(date: React.SetStateAction<any>) =>
                    setEndDate(date)
                  }
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  //
                  maxDate={addDays(startDate, 30)}
                />
              </div>
            </DatePickerBox>

            {permitCode === headOfficerManagerCode && (
              <div
                style={{
                  display: 'flex',
                  // marginRight: '5px',
                  marginLeft: '5px',
                }}
              >
                <Button
                  label="계약 단지 선택"
                  onClick={() =>
                    onApartmentContractInfo({
                      apartmentListContractData,
                      setApartmentListContractData,
                      setOnModal,
                    })
                  }
                />

                <ApartmentNameBox>
                  <ApartmentIdx style={{ marginRight: '5px' }}>
                    {apartmentContractIdx}
                  </ApartmentIdx>
                  <ApartmentName>{apartmentName}</ApartmentName>
                </ApartmentNameBox>
              </div>
            )}

            {permitCode === apartmentManagerCode &&
              apartmentListContractData.map((item, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => {
                      onClickedButton(item);
                    }}
                    style={{ fontSize: '13px' }}
                  >
                    <PrintBtn
                      style={{
                        fontSize: '13px',
                        marginLeft: '5px',
                      }}
                    >
                      {item.JobGroupEtc}
                    </PrintBtn>
                  </div>
                );
              })}
          </DatePickerBoxWrap>

          <PrintBtnSection>
            {/* <p style={{ marginRight: '5px' }}>
              <Button
                label="근무 내역 불러오기"
                onClick={() =>
                  onLoadBtn({
                    setLoading,
                    setRecordListData,
                    apartmentContractIdx,
                    selectedContractStart,
                    selectedContractEnd,
                  })
                }
              />
            </p> */}

            {/* <PrintBtn onClick={() => onPersonalWorkCardModal()}> */}
            <PrintBtn onClick={() => onMoveToPrintPage()}>
              근무 보고서 인쇄
            </PrintBtn>

            <PrintBtn
              style={{ marginLeft: '5px' }}
              onClick={() => onExportCSVFile()}
            >
              근무 내역 내보내기(엑셀)
            </PrintBtn>
          </PrintBtnSection>
        </DatePickerSection>

        {/* <Bar />
        <LoadInfoSection>
          <LoadBtnSection>
            {permitCode === headOfficerManagerCode && (
              <div style={{ marginRight: '5px' }}>
                <Button
                  label="계약 단지 선택"
                  onClick={() =>
                    onApartmentContractInfo({
                      apartmentListContractData,
                      setApartmentListContractData,
                      setOnModal,
                    })
                  }
                />
              </div>
            )} */}

        {/* <Button
              label="근무 내역 불러오기"
              onClick={() =>
                onLoadBtn({
                  setLoading,
                  setRecordListData,
                  apartmentContractIdx,
                  selectedContractStart,
                  selectedContractEnd,
                })
              }
            /> */}
        {/* </LoadBtnSection> */}

        {/* <ApartmentNameBox> */}
        {/* <p>단지 이름</p> */}
        {/* <ApartmentIdx style={{ marginRight: '5px' }}>
              {apartmentContractIdx}
            </ApartmentIdx>
            <ApartmentName>
              {apartmentName}
            </ApartmentName>
          </ApartmentNameBox> */}
        {/* </LoadInfoSection> */}

        <LoadedDataSection>
          <HistorySection
            loading={loading}
            recordListData={recordListData}
            apartmentName={apartmentName}
          />
        </LoadedDataSection>
      </Section>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const Section = styled.div`
  /* */
`;

const DatePickerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
`;

const DatePickerBoxWrap = styled.div`
  display: flex;
`;

const DatePickerBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  p {
    /* width: 100%; */
    margin-right: 5px;

    &.end-date {
      margin-left: 10px;
    }
  }
`;

const PrintBtnSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrintBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};
`;

const LoadInfoSection = styled.div`
  display: flex;
  padding: 0 30px;
  height: 40px;
`;

const LoadBtnSection = styled.div`
  display: flex;
  align-items: center;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;

const ApartmentName = styled.div`
  /* font-weight: 600; */
`;

const LoadedDataSection = styled.div`
  /* padding: 20px; */
  /* background-color: gray; */
`;
